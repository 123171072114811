import React from "react";
import SEO from "components/seo";
import Layout from "components/layout";
import Dallas from "components/locationIndexPages/Dallas/index";

function index() {
  const title = "Dallas Translation Services - Translation Agency";
  const description =
    " Looking for translation services in Dallas? You're in luck. Tomedes swiftly translates into over 120 languages and 950+ language pairs. 1-Year Guarantee | Fast Delivery | 24/7 Availability";
  const keywords = "Translation Services in Dallas";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/dallas-translation-services"
      />
      <Dallas />
    </Layout>
  );
}

export default index;
